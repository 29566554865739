import React, { useEffect, useState } from 'react';
import { RouteInstructionsI } from '../../utils/types';
import {
  ActionsContainer,
  CloseButton,
  Container,
  Instruction,
  InstructionText,
  InstructionsContainer,
} from './styles';
import translate from 'translate';
import { Skeleton } from 'antd';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const RouteInstructions: React.FC<RouteInstructionsI> = ({
  // map,
  origin,
  route,
  onClose,
  isOpen,
}) => {
  const [displayRoute, setDisplayRoute] = useState<
    google.maps.DirectionsRoute[]
  >([]);
  const [instructions, setInstructions] = useState<
    google.maps.DirectionsStep[]
  >([]);
  const [texts, setTexts] = useState<string[]>([]);
  const [open, setOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(true);
  const [directionsService, setDirectionsService] = useState<
    google.maps.DirectionsService | undefined
  >(undefined);
  const [directionsRenderer, setDirectionsRenderer] = useState<
    google.maps.DirectionsRenderer | undefined
  >(undefined);
  const [isShowInstructions, setIsShowInstructions] = useState(true);
  const [selected, setSelected] = useState(0);

  const map = useMap();
  const routesLib = useMapsLibrary('routes');

  function handleClose() {
    setDisplayRoute([]);
    directionsRenderer?.set('directions', null);
    map?.setCenter(origin);
    map?.setZoom(17);
    onClose();
  }

  function toggleShowInstructions() {
    setIsShowInstructions(!isShowInstructions);
  }

  // async function getInstructions() {
  //   const translatedArr: string[] = [];
  //   for (const instruction of route.instructions) {
  //     await translate(instruction.text, 'pt')
  //       .then(translated => {
  //         translatedArr.push(translated);
  //       })
  //       .catch(error => console.log(error));
  //   }
  //   setTexts(translatedArr);
  // }

  useEffect(() => {
    if (!directionsService || !directionsRenderer || !route) return;
    if (directionsRenderer.getDirections())
      directionsRenderer.set('directions', null);
    directionsService
      .route({
        origin,
        destination: route,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
        language: 'pt-br',
      })
      .then(resp => {
        directionsRenderer.setDirections(resp);
        setDisplayRoute(resp.routes);
      });
  }, [directionsService, directionsRenderer, origin, route]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!routesLib || !map) return;
    setDirectionsService(new routesLib.DirectionsService());
    setDirectionsRenderer(new routesLib.DirectionsRenderer({ map }));
  }, [routesLib, map]);

  useEffect(() => {
    if (!displayRoute || !open) return;
    if (!displayRoute[selected]) return;
    console.log(displayRoute[selected].legs[0].steps);
    setInstructions(displayRoute[selected].legs[0].steps);
    setIsLoading(false);
  }, [displayRoute, open]);

  return route && isOpen ? (
    <Container>
      <ActionsContainer>
        <CloseButton id="instructions" onClick={toggleShowInstructions}>
          {isShowInstructions ? 'Minimizar instruções' : 'Mostrar instruções'}
        </CloseButton>
        <CloseButton onClick={handleClose}>Fechar rota</CloseButton>
      </ActionsContainer>
      {isShowInstructions && (
        <InstructionsContainer>
          <Skeleton active loading={isLoading}>
            {instructions.map((instruction, index) => (
              <Instruction key={index}>
                <InstructionText>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: instruction.instructions,
                    }}
                  ></p>
                  {instruction.distance && <p>{instruction.distance?.text}</p>}
                </InstructionText>
              </Instruction>
            ))}
          </Skeleton>
        </InstructionsContainer>
      )}
    </Container>
  ) : null;
};

export default RouteInstructions;

import styled from 'styled-components';
import PathSVG from '../../assets/img/inform-path.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #0000002f;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: #ffffff;
  max-height: calc(50% - 10px);
  width: 40%;
`;

const InstructionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow-y: scroll;
  margin: 10px 0;
  padding: 0 10px;
`;

const Instruction = styled.li`
  position: relative;
  width: 100%;
  padding-left: 5%;
  margin-bottom: 1%;

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-image: url(${PathSVG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 18px;
    height: 18px;
    left: 0;
  }
`;

const InstructionText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;
  margin-left: 10px;

  span,
  p:last-child {
    white-space: nowrap;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: stretch;
`;

const CloseButton = styled.button`
  flex: 1;
  cursor: pointer;
  /* border: 1px solid #000000; */
  /* border-radius: 6px; */
  border: none;
  background-color: #ffffff;
  box-shadow: 0 0 5px #0000002f;
  font-size: 18px;
  /* font-weight: bold; */
  color: #585858;
  width: fit-content;
  padding: 8px 10px;
  max-height: 5%;

  &:hover {
    color: #000000;
    background-color: #ebebeb;
  }

  &#instructions {
    flex: 2;
  }
`;

export {
  Container,
  InstructionsContainer,
  Instruction,
  ActionsContainer,
  CloseButton,
  InstructionText,
};

import React, { useEffect, useState } from 'react';
import {
  AvatarContent,
  Card,
  CardLabel,
  Container,
  Flag,
  Info,
  ButtonText,
  InformButton,
  ItemContainer,
  ItemData,
  ItemText,
  LabelText,
  ModalInfo,
  ModalLabel,
  ViewModal,
  ButtonContainer,
  ShowRoute,
  InfoButtonContainer,
  InfoButton,
  InfoUserList,
  InfoInstitutionUser,
  InfoUserIcon,
} from './styles';
import { InstitutionNumbersI, type InformI } from '../../utils/types';
import EmergencySVG from '../../assets/img/emergency-flag.svg';
import PanicSVG from '../../assets/img/panic-flag.svg';
import PathSVG from '../../assets/img/inform-path.svg';
import DistanceSVG from '../../assets/img/inform-distance.svg';
import TimeSVG from '../../assets/img/inform-time.svg';
import CalendarSVG from '../../assets/img/calendar.svg';
import { Avatar } from 'antd';
import {
  UserOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
// import 'leaflet-routing-machine';
import { setStorage } from '../../utils/storage';
import { objectToBase64 } from '../../utils/converters';
import { useQuery } from '@apollo/client';
import {
  OCCURRENCE_BY_ID,
  VALIDATION_USER,
} from '../../utils/queries/institutionQueries';
import { UserE } from '../../utils/entities';
import { distanceKmBetweenPoints } from '../../utils/geofunction';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

const Inform: React.FC<InformI> = ({
  id,
  text,
  count,
  type,
  level,
  institutionId,
  address,
  coordinates,
  onShowRoute,
  person,
  personFunction,
  images,
  date,
  hour,
  responsible,
  responsibleNumber,
  origin,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [institutionNumbers, setInstitutionNumbers] = useState<
    InstitutionNumbersI | undefined
  >();
  const [directionsService, setDirectionsService] = useState<
    google.maps.DirectionsService | undefined
  >(undefined);
  const [distance, setDistance] = useState<string | undefined>('');
  const [time, setTime] = useState<string | undefined>('');
  // const [controlMap, setControlMap] = useState<any>();

  const queryMemberCount = useQuery(VALIDATION_USER, {
    variables: {
      institution: institutionId,
    },
  });

  const routesLib = useMapsLibrary('routes');

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showRoute = () => {
    if (!coordinates) return;
    // onShowRoute?.([coordinates[1], coordinates[0]]);
    onShowRoute?.({ lat: coordinates[1], lng: coordinates[0] });
  };

  const queryOccurrence = useQuery(OCCURRENCE_BY_ID, {
    variables: {
      id,
    },
  });

  function generatePDf() {
    queryOccurrence.refetch({ id }).then(resp => {
      setStorage(
        `${process.env.REACT_APP_PDF_FILE}`,
        objectToBase64(resp.data.getOccurrenceById),
      );
      window.open('/security-bodies/single-pdf', '_blank');
    });
  }

  useEffect(() => {
    (() => {
      queryMemberCount.refetch({ institution: institutionId }).then(data => {
        const users = data.data.getUserByInstitution;
        if (!users || users.length < 1) return;

        const obj: InstitutionNumbersI = {
          students: users.filter((user: any) => user.type === 'STUDENT').length,
          teachers: users.filter((user: any) => user.type === 'TEACHER').length,
          employees: users.filter((user: any) => user.type === 'EMPLOYEE')
            .length,
        };
        setInstitutionNumbers(obj);
      });
    })();
  }, [queryMemberCount.loading]);

  useEffect(() => {
    (async () => {
      if (!directionsService) return;
      // console.log('Origem:', origin);
      // console.log('Destino:', coordinates);
      if (
        !origin ||
        !coordinates ||
        origin.length === 0 ||
        coordinates.length === 0
      )
        return;
      directionsService
        .route({
          origin: { lat: origin[0], lng: origin[1] },
          destination: { lat: coordinates[1], lng: coordinates[0] },
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          provideRouteAlternatives: false,
        })
        .then(result => {
          const route = result.routes[0].legs[0];
          // console.log(route);
          setDistance(route.distance?.text);
          setTime(
            route.duration?.text
              .replaceAll('s', '')
              .replace(' hora', 'h')
              .replace(' minuto', 'min'),
          );
        });
    })();
  }, [directionsService, origin, coordinates]);

  useEffect(() => {
    if (!routesLib) return;
    setDirectionsService(new routesLib.DirectionsService());
  }, [routesLib]);

  return (
    <>
      <Container type={type} id={id}>
        <Card
          ghost
          items={[
            {
              key: '1',
              label: (
                <CardLabel>
                  <img
                    width={30}
                    draggable={false}
                    src={
                      type === 'EMERGENCY'
                        ? require('../../assets/img/emergency-image.png')
                        : require('../../assets/img/panic-image.png')
                    }
                    alt=""
                  />
                  <LabelText>
                    {text === null
                      ? 'Aguarde a Instituição descrever a ocorrência...'
                      : text}
                  </LabelText>
                  <AvatarContent>
                    {images ? (
                      <Avatar.Group maxCount={1} size="default">
                        {images.map((image, index) => {
                          return (
                            <Avatar
                              key={index}
                              icon={
                                image ? <img src={image} /> : <UserOutlined />
                              }
                            />
                          );
                        })}
                      </Avatar.Group>
                    ) : null}
                  </AvatarContent>
                </CardLabel>
              ),
              children: (
                <ItemContainer>
                  <ItemData>
                    <img width={20} draggable={false} src={PathSVG} alt="" />
                    <ItemText>{address}</ItemText>
                  </ItemData>
                  <ItemData>
                    <img
                      width={20}
                      draggable={false}
                      src={CalendarSVG}
                      alt=""
                    />
                    <ItemText>{`${date} - ${hour}`}</ItemText>
                  </ItemData>
                  <ItemData>
                    <img
                      width={20}
                      draggable={false}
                      src={DistanceSVG}
                      alt=""
                      style={{ rotate: '90deg' }}
                    />
                    <ItemText>{distance}</ItemText>
                  </ItemData>
                  <ItemData>
                    <img width={20} draggable={false} src={TimeSVG} alt="" />
                    <ItemText>{time}</ItemText>
                  </ItemData>
                  <ShowRoute>
                    <EnvironmentOutlined />
                    <InfoButtonContainer>
                      <InfoButton onClick={showRoute}>Mostrar rota</InfoButton>
                    </InfoButtonContainer>
                  </ShowRoute>
                  <ButtonContainer>
                    <InformButton onClick={showModal}>
                      <InfoCircleOutlined />
                      <ButtonText>Mais informações</ButtonText>
                    </InformButton>
                    <InformButton onClick={generatePDf}>
                      <PrinterOutlined />
                      <ButtonText>Relatório</ButtonText>
                    </InformButton>
                  </ButtonContainer>
                </ItemContainer>
              ),
              showArrow: false,
            },
          ]}
        />
      </Container>
      <ViewModal
        centered
        title={
          type === 'EMERGENCY'
            ? `EMERGÊNCIA ${
                level === undefined
                  ? ''
                  : level === 'LOW'
                    ? 'BAIXA'
                    : level === 'MEDIUM'
                      ? 'MÉDIA'
                      : 'ALTA'
              }`
            : 'PÂNICO'
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        {type === 'EMERGENCY' ? (
          <Flag src={EmergencySVG} alt="" draggable={false} />
        ) : (
          <Flag src={PanicSVG} alt="" draggable={false} />
        )}
        <ModalInfo>
          <ModalLabel>ACIONADO POR</ModalLabel>
          <Info>
            {count! > 1
              ? `${
                  person +
                  ' - ' +
                  personFunction +
                  ' + ' +
                  (count! - 1) +
                  ' pessoa(s)'
                }`
              : person + ' - ' + personFunction}
          </Info>
        </ModalInfo>
        <ModalInfo>
          <Info></Info>
        </ModalInfo>
        <ModalInfo>
          <ModalLabel>DATA & HORA</ModalLabel>
          <Info>
            {`${date} - ${hour}`}
            {/* {date?.toLocaleDateString()} -{' '}
            {Number(hour?.getMinutes().toLocaleString()) <= 9
              ? `${hour?.getHours().toLocaleString()}` +
                ':0' +
                `${hour?.getMinutes().toLocaleString()}`
              : `${hour?.getHours().toLocaleString()}` +
                ':' +
                `${hour?.getMinutes().toLocaleString()}`} */}
          </Info>
        </ModalInfo>
        <ModalInfo>
          <ModalLabel>DESCRIÇÃO</ModalLabel>
          <Info>
            {text === null
              ? 'Aguarde a Instituição descrever a ocorrência...'
              : text}
          </Info>
        </ModalInfo>
        <ModalInfo>
          <ModalLabel>ENDEREÇO</ModalLabel>
          <Info>{address}</Info>
        </ModalInfo>
        <ModalInfo>
          <ModalLabel>RESPONSÁVEL & TELEFONE</ModalLabel>
          <Info>
            {responsible} - {responsibleNumber}
          </Info>
        </ModalInfo>
        <ModalInfo>
          <ModalLabel>PESSOAS NA INSTITUIÇÃO</ModalLabel>
          <InfoUserList>
            <InfoInstitutionUser>
              <InfoUserIcon
                src={require('../../assets/img/students-image.png')}
                alt="alunos"
              />
              Alunos: {institutionNumbers?.students || 0}
            </InfoInstitutionUser>
            <InfoInstitutionUser>
              <InfoUserIcon
                src={require('../../assets/img/teachers-image.png')}
                alt="professores"
              />
              Professores: {institutionNumbers?.teachers || 0}
            </InfoInstitutionUser>
            <InfoInstitutionUser>
              <InfoUserIcon
                src={require('../../assets/img/employees-image.png')}
                alt="funcionários"
              />
              Funcionários: {institutionNumbers?.employees || 0}
            </InfoInstitutionUser>
          </InfoUserList>
        </ModalInfo>
      </ViewModal>
    </>
  );
};

export default Inform;

import React from 'react';
import { MarkerI } from '../../utils/types';
import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';

const Marker: React.FC<MarkerI> = ({ position, title }) => {
  const map = useMap();

  function handleClick(e: google.maps.MapMouseEvent) {
    map?.setCenter(position);
    map?.setZoom(17);
  }

  return (
    <AdvancedMarker position={position} onClick={handleClick} zIndex={100} />
  );
};

export default Marker;

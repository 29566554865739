import React from 'react';

import { Container } from './styles';
import { Map } from '@vis.gl/react-google-maps';
import { MapI } from '../../utils/types';

const GoogleMap: React.FC<MapI> = ({
  coordinates,
  isRoute,
  isRouteModalOpen,
  onHideRoute,
  onOpenRouteModal,
  position = false,
  children,
}) => {
  return (
    <Container>
      <Map
        mapId={'map'}
        defaultZoom={17}
        defaultCenter={{ lat: coordinates[0], lng: coordinates[1] }}
        fullscreenControl={false}
      >
        {children}
      </Map>
    </Container>
  );
};

export default GoogleMap;
